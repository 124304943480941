<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">KABUPATEN</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearch()"
                v-model="search"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAME KABUPATEN</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.nama_kabupaten | capitalize }}</td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="pilihKabupaten(data.id_kabupaten, data.nama_kabupaten)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="page--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
            <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> -->
          </div>
        </div>
      </div>
      <!-- end Modal -->
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE KECAMATAN</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Nama Kabupaten"
                v-model="kecamatan.nama_kabupaten"
                id="nama_kabupaten"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Nama Kabupaten"
                v-model="kecamatan.id_kabupaten"
                id="id_kabupaten"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Nama Kecamatan" v-model="kecamatan.nama_kecamatan" dense outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'kecamatans-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Kecamatan',
          disabled: false,
          href: '/admin/kecamatans',
        },
        {
          text: 'Create Kecamatan',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      kecamatan: {
        id_kabupaten: '',
        nama_kecamatan: '',
      },
      errors: [],

      kabupaten: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.getAllKabupaten()
      this.$isLoading(false)
    }, 2000)
  },
  filters: {
    capitalize: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      })
      return capitalized.join(' ')
    },
  },
  methods: {
    getAllKabupaten() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/admin/kabupatens'
      return this.axios.get(url).then(response => {
        this.kabupaten = response.data.kabupatens
      })
    },
    CreateData(e) {
      // console.log('id_kabupaten: ' + this.users.id_kabupaten)

      if (this.$data.kecamatan.id_kabupaten.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama kabupaten wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.kecamatan.nama_kecamatan.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama kecamatan wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + '/api/admin/kecamatans/store'

        this.axios
          .post(uri, this.kecamatan)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Kecamatan created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error!',
              html: this.kecamatan.nama_kecamatan + ' sudah digunakan !',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'kecamatans-index' })
        }, 3000)
      }
    },
    doSearch() {
      let go = this.kabupaten.filter(item => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_kabupaten.toLowerCase().includes(v))
      })
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.kabupaten)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.kabupaten.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(kabupaten) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return kabupaten.slice(from, to)
    },

    pilihKabupaten(id_kabupaten, nama_kabupaten) {
      document.querySelector('#id_kabupaten').setAttribute('value', id_kabupaten)
      document.querySelector('#nama_kabupaten').setAttribute('value', nama_kabupaten)
      this.kecamatan.id_kabupaten = id_kabupaten
      this.kecamatan.nama_kabupaten = nama_kabupaten
    },
  },
  watch: {
    kabupaten() {
      this.pages = []
      this.setPages()
    },
  },
}
</script>
